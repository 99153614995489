// SupportTile (core/sections/_support-tile.scss) ---------------
$support-tile--padding-t__mobile: 		null;		// support-tile, top padding (mobile)
$support-tile--padding-b__mobile: 		null;		// support-tile, bottom padding (mobile)
$support-tile--padding-t__desktop: 		null;		// support-tile, top padding (desktop)
$support-tile--padding-b__desktop: 		null;		// support-tile, bottom padding (desktop)
$support-tile-header--padding__mobile: 	null;		// support-tile header, bottom padding (mobile)
$support-tile-header--padding__desktop:	null;		// support-tile header, bottom padding (desktop)
$support-tile-items--padding__mobile: 	null;		// padding between support-tile items (mobile)
$support-tile-items--padding__desktop: 	null;		// padding between support-tile items (desktop)
$support-tile-item--width: 				344px;		// support-tile item, width
$support-tile-item--inner-padding-v: 	24px;		// support-tile item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$support-tile-item--inner-padding-h: 	32px;		// support-tile item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
