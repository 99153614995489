.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: get-color(primary, 1);
  color: #ffffff
}

.accordion-title:hover {
  background-color: get-color(secondary, 1);
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: get-color(light, 1);
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}
