// Features tiles (core/sections/_about.scss) ---------
$about--padding-t__mobile: 		null;		// features tiles, top padding (mobile)
$about--padding-b__mobile:			null;		// features tiles, bottom padding (mobile)
$about--padding-t__desktop: 		null;		// features tiles, top padding (desktop)
$about--padding-b__desktop: 		null;		// features tiles, bottom padding (desktop)
$about-header--padding__mobile: 	null;		// features tiles header, bottom padding (mobile)
$about-header--padding__desktop: 	null;		// features tiles header, bottom padding (desktop)
$about-items--padding__mobile: 	32px;		// padding between features tiles (mobile)
$about-items--padding__desktop: 	64px;		// padding between features tiles (desktop)
$about-item--width: 				317px;		// features tiles item, width
$about-item--inner-padding-v: 		0;			// features tiles item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$about-item--inner-padding-h: 		0;			// features tiles item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
