.support-tile {
  .section-inner {
    padding-top: $support-tile--padding-t__mobile;
    padding-bottom: $support-tile--padding-b__mobile;
  }

  .section-header {
    padding-bottom: $support-tile-header--padding__mobile;
  }

  .tiles-wrap {
    @if ($support-tile-items--padding__mobile != null) {
      margin-right: -($support-tile-items--padding__mobile * 0.5);
      margin-left: -($support-tile-items--padding__mobile * 0.5);
      margin-top: -($support-tile-items--padding__mobile * 0.5);

      &:last-of-type {
        margin-bottom: -($support-tile-items--padding__mobile * 0.5);
      }

      &:not(:last-of-type) {
        margin-bottom: ($support-tile-items--padding__mobile * 0.5);
      }
    }

    &.push-left {
      &::after {
        flex-basis: $support-tile-item--width;
        max-width: $support-tile-item--width;
        @if ($support-tile-items--padding__mobile != null) {
          padding-left: $support-tile-items--padding__mobile * 0.5;
          padding-right: $support-tile-items--padding__mobile * 0.5;
        }
      }
    }
  }

  .tiles-item {
    display: flex;
    justify-content: center;
    flex-basis: $support-tile-item--width;
    max-width: $support-tile-item--width;
    @if ($support-tile-items--padding__mobile != null) {
      padding: $support-tile-items--padding__mobile * 0.5;
    }
  }

  .tiles-item-inner {
    padding-top: $support-tile-item--inner-padding-v;
    padding-bottom: $support-tile-item--inner-padding-v;
    padding-left: $support-tile-item--inner-padding-h;
    padding-right: $support-tile-item--inner-padding-h;
  }
}

.support-tile-item-content {
  flex-grow: 1;
}

.support-tile-item-source {
  display: inline-flex; // to allow centering when using .content-center on a parent tag
  flex-wrap: wrap;
  align-items: center;
}

@include media('>medium') {
  .support-tile {
    .section-inner {
      padding-top: $support-tile--padding-t__desktop;
      padding-bottom: $support-tile--padding-b__desktop;
    }

    .section-header {
      padding-bottom: $support-tile-header--padding__desktop;
    }

    @if ($support-tile-items--padding__desktop != null) {
      .tiles-wrap {
        margin-right: -($support-tile-items--padding__desktop * 0.5);
        margin-left: -($support-tile-items--padding__desktop * 0.5);
        margin-top: -($support-tile-items--padding__desktop * 0.5);

        &:last-of-type {
          margin-bottom: -($support-tile-items--padding__desktop * 0.5);
        }

        &:not(:last-of-type) {
          margin-bottom: ($support-tile-items--padding__desktop * 0.5);
        }

        &.push-left {
          &::after {
            padding-left: $support-tile-items--padding__desktop * 0.5;
            padding-right: $support-tile-items--padding__desktop * 0.5;
          }
        }
      }

      .tiles-item {
        padding: $support-tile-items--padding__desktop * 0.5;
      }
    }
  }
}

.help-icon {
  color: get-color(dark, 1) !important;

  &:hover {
    color: get-color(primary, 2) !important;
  }
}
